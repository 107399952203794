// App.tsx
import { ChakraProvider, useDisclosure } from "@chakra-ui/react";
import theme from "./theme";
import Layout from "./components/Layout";
import ConnectButton from "./components/ConnectButton";
import AccountModal from "./components/AccountModal";
import Psactive from "./components/Psactive";
import "@fontsource/inter";
import toks from "./images/toks.png";

function App() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <ChakraProvider theme={theme}>
      <Layout>
    <div>
      <img src={toks} alt="Toks Invasion" />
    </div>
        <ConnectButton handleOpenModal={onOpen} />
        <AccountModal isOpen={isOpen} onClose={onClose} />
        <Psactive />
      </Layout>
    </ChakraProvider>
  );
}

export default App;
