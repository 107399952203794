import { useState} from "react";
import { Box, Flex, Text, Button, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper } from "@chakra-ui/react";
import { useSaleactive, usePsactive, useGsactive, useContractMethod } from "../hooks";
import { useEthers } from "@usedapp/core";
import { utils } from "ethers";
import axios from "axios";

export default function Minter() {

  const psactive = usePsactive();
  const gsactive = useGsactive();
  const sactive = useSaleactive();

  const { account } = useEthers();
  const { state: presaleState, send: presale } = useContractMethod("presale");
  const { state: mintstate, send: mint} = useContractMethod("mint");

  const [input, setInput] = useState("");
  let ethgl = "31000"

  async function handlePresale() {
    let _presaleamt = parseInt(input);
    let mp = {};
    let address = account;
    if (_presaleamt) {
    let mproof = axios.get('https://wdrqm50sj2.execute-api.us-east-1.amazonaws.com/toks-merkle-gas-prod/' + address).then(response => {
      mp = response.data;
      let cost = (0 * _presaleamt);
      let ethval = utils.parseEther(cost.toString());
      presale(mp, _presaleamt, { value: ethval });
    });
    }
  }

  function handleMint() {
    const _mintamt = parseInt(input);
      let cost = (.05 * _mintamt);
      let ethval = utils.parseEther(cost.toString());
    if (_mintamt) {
      mint(_mintamt, { value: ethval });
    }
  }


  function handleGomint() {
    const _mintamt = 1;
      let cost = 0;
      let ethval = utils.parseEther(cost.toString());
      mint(_mintamt, { value: ethval });
  }

  function handleInput(valueAsString: string, valueAsNumber: number) {
    setInput(valueAsString);
  }

  if (account && gsactive && !sactive)
  return (
    <Flex direction="column" align="center" mt="4">
      <Text color="white" fontSize="2xl">
        {gsactive ? gsactive.toString() : ""}
        </Text>
      <Box mt={4}>
        <NumberInput
          step={1}
          defaultValue={0}
          min={1}
          max={7}
          mb={2}
          onChange={handleInput}
          color="white"
        >
          <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
        </NumberInput>
      </Box>
        <Button
        colorScheme="teal"
        size="lg"
        onClick={handlePresale}>
        Mint TOKS!
      </Button>
    </Flex>
  );
  else if (account && !psactive && !sactive)
  return (
    <Flex direction="column" align="center" mt="4">
      <Text color="white" fontSize="2xl">
        You're all set! Hold tight...
        </Text>
    </Flex>
  );

  else if (account && !gsactive && sactive)
  return (
    <Flex direction="column" align="center" mt="4">
      <Text color="white" fontSize="2xl">
        {sactive}
        </Text>
      <Box mt={4}>
        <NumberInput
          step={1}
          defaultValue={0}
          min={1}
          max={10}
          mb={2}
          onChange={handleInput}
          color="white"
        >
          <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
        </NumberInput>
      </Box>
        <Button
        colorScheme="orange"
        size="lg"
        onClick={handleMint}>
        Mint TOKS!
      </Button>
    </Flex>
  );

  else if (account && gsactive && sactive)
  return (
    <Flex direction="column" align="center" mt="4">
      <Text color="white" fontSize="2xl">
        {gsactive}
        </Text>
        <Button
        colorScheme="green"
        size="lg"
        onClick={handleGomint}>
        Mint TOK!
      </Button>
    </Flex>
  );
  return (
    <Flex direction="column" align="center" mt="4">
      <Text color="white" fontSize="2xl">
        ^^ Please connect ^^
        </Text>
    </Flex>
  );
}
