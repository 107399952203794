// ConnectButton.tsx
import Identicon from "./Identicon";
import { Button, Box, Text } from "@chakra-ui/react";
import { useEthers, useEtherBalance } from "@usedapp/core";
import { formatEther } from "@ethersproject/units";

type Props = {
  handleOpenModal: any;
};

export default function ConnectButton({ handleOpenModal }: Props) {
  const {activateBrowserWallet, account } = useEthers();
  const etherBalance = useEtherBalance(account);

  function handleConnectWallet() {
    activateBrowserWallet();
  }

   return account ? (
     <Box
       display="flex"
       alignItems="center"
       background="gray.700"
       borderRadius="x1"
       py="0"
     >
       <Box px="5">
         <Text color="white" fontSize="2xl">
           Acct Balance: {etherBalance && parseFloat(formatEther(etherBalance)).toFixed(2)} ETH
         </Text>
       </Box>
       <Button
         onClick={handleOpenModal}
         bg="gray.800"
         border="1px solid transparent"
         _hover={{
           border: "1px",
           borderStyle: "solid",
           borderColor: "blue.400",
           backgroundColor: "blue.700",
         }}
         borderRadius="x1"
         m="1px"
         px={3}
         height="38px"
       >
         <Text color="white" fontSize="2xl" fontWeight="medium" mr="2">
           {account &&
             `${account.slice(0, 6)}...${account.slice(
               account.length - 4,
               account.length
             )}`}
         </Text>
         <Identicon />
       </Button>
       </Box>
   ) : (
     <Button onClick={handleConnectWallet}>
       Connect to ETH Wallet
     </Button>
   );
}
