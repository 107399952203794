// hooks/index.ts

import { ethers, utils } from "ethers";
import { Contract } from "@ethersproject/contracts";
import { useContractCall, useContractFunction } from "@usedapp/core";
import toksAbi from "../abi/toksAbi.json"
import { toksAddress } from "../contracts"

const toksInterface = new ethers.utils.Interface(toksAbi);
const contract = new Contract(toksAddress, toksInterface);

export function useContractMethod(methodName: string) {
  const { state, send } = useContractFunction(contract, methodName, {});
  return { state, send };
}

export function usePsactive() {
  const [psactive]: any = useContractCall({
      abi: toksInterface,
      address: toksAddress,
      method: "_presaleActive",
      args: [],
    }) ?? [];
    if (psactive) {
      return "Presale: Enter qty (2 max), click to Mint!";
    }
}

export function useGsactive() {
  const [gsactive]: any = useContractCall({
      abi: toksInterface,
      address: toksAddress,
      method: "_presaleActive",
      args: [],
    }) ?? [];
    if (gsactive) {
      return "Gas Only: click to Mint Free!";
    }
}

// export function useSaleactive() {
//   const [sactive]: any = useContractCall({
//       abi: toksInterface,
//       address: toksAddress,
//       method: "_active",
//       args: [],
//     }) ?? [];
//     if (sactive) {
//       return "Public Sale: Enter qty (10 max), click to Mint!";
//     }
// }


export function useSaleactive() {
  const [sactive]: any = useContractCall({
      abi: toksInterface,
      address: toksAddress,
      method: "_active",
      args: [],
    }) ?? [];
    if (sactive) {
      return "Public Sale: Enter qty (10 max), click to Mint!";
    }
}
